// @flow

type Item = {
  id: number | string,
  label: string,
  [string]: any
};

/**
 * Filters and optionally sorts items based on input value and selected items
 * @param {Array<number | string>} selectedItems - Array of identifiers for already selected items
 * @param {string} inputValue - The search input value to filter items by
 * @param {Array<Item>} data - Array of items to filter
 * @param {Array<string>} keys - Array containing two keys: [identifier, display]
 * @param {boolean} [sortData=true] - Whether to sort the filtered results alphabetically
 * @returns {Array<Item>} Filtered (and optionally sorted) array of items that match the input value
 *                        and are not already selected
 */
export function getFilteredItems(
  selectedItems: Array<number | string>,
  inputValue: string,
  data: Array<Item>,
  keys: Array<string>,
  sortData: boolean = true
): Array<Item> {
  const identifier = keys[0];
  const display = keys[1];
  const lowerCasedInputValue = inputValue.toLowerCase();
  const filteredData = data.filter(
    item =>
      !selectedItems.includes(item[identifier]) &&
      // $FlowFixMe - Optional chaining
      item[display]?.toLowerCase().includes(lowerCasedInputValue)
  );
  return sortData
    ? filteredData.sort((a: Item, b: Item) =>
        a[display].localeCompare(b[display])
      )
    : filteredData;
}
