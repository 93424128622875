// @flow

import React, { useState, useCallback, useRef } from "react";
import * as R from "ramda";
import { useCombobox, useMultipleSelection } from "downshift";

import {
  Container,
  Selection as StyledSelection,
  Label,
  DropdownMenu,
  ItemTitle,
  ToggleButton,
  SelectAllWrapper
} from "./styles";
import { red500 } from "src/styles/constants/colors";
import Dropdown from "./Dropdown";
import VirtualDropdown from "./VirtualDropdown";
import Selection from "./Selection";
import VirtualSelection from "./VirtualSelection";

import { getFilteredItems } from "./utils";

type Props = {
  label?: string,
  labelWeight?: "normal" | "bold",
  placeholder?: string,
  data: Array<Object>,
  selected: Array<any>,
  onChange: Function,
  keys: Array<string>,
  showIcon?: boolean,
  showSelectAll?: boolean,
  showCheckBox?: boolean,
  virtualized?: boolean,
  virtualRowHeight?: number,
  itemsPerRow?: number,
  sortData?: boolean
};

const MultiSelect = ({
  label = "",
  labelWeight = "normal",
  placeholder = "Select items...",
  data = [],
  selected,
  onChange,
  keys,
  showIcon = false,
  showSelectAll = false,
  showCheckBox = false,
  virtualized = false,
  virtualRowHeight,
  itemsPerRow,
  sortData = true
}: Props) => {
  const identifier: string = keys[0];

  const [searchTerm, setSearchTerm] = useState("");
  const selectedItemsRef = useRef(null);

  const filteredItems = React.useMemo(
    () => getFilteredItems(selected, searchTerm, data, keys, sortData),
    [selected, searchTerm, data, keys, sortData]
  );
  const handleSelectAll = () => {
    const allFilteredValues = R.map(({ id }) => id, filteredItems);
    const allSelectedItems = R.uniq([...selected, ...allFilteredValues]);
    onChange(allSelectedItems);
    setSearchTerm("");
    if (!showCheckBox) closeMenu();
  };

  const handleDeselectAll = () => {
    onChange([]);
    openMenu();
    setSearchTerm("");
  };

  const { getSelectedItemProps, getDropdownProps, removeSelectedItem } =
    useMultipleSelection({
      selectedItems: selected,
      onStateChange({ selectedItems: newSelectedItems, type }) {
        switch (type) {
          case useMultipleSelection.stateChangeTypes.FunctionRemoveSelectedItem:
            onChange(newSelectedItems);
            break;
          default:
            break;
        }
      }
    });
  const {
    isOpen,
    openMenu,
    closeMenu,
    getLabelProps,
    getMenuProps,
    getComboboxProps,
    getInputProps,
    highlightedIndex,
    getItemProps
  } = useCombobox({
    items: filteredItems,
    inputValue: searchTerm,
    selectedItem: null,
    stateReducer(state, actionAndChanges) {
      const { changes, type } = actionAndChanges;

      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          return {
            ...changes,
            ...(changes.selectedItem && {
              isOpen: true,
              highlightedIndex
            })
          };
        case useCombobox.stateChangeTypes.InputBlur:
          return {
            ...changes,
            isOpen: false
          };
        default:
          return changes;
      }
    },
    onStateChange({
      inputValue: newInputValue,
      type,
      selectedItem: newSelectedItem
    }) {
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          if (!newSelectedItem) return;
          if (showCheckBox) {
            const value = newSelectedItem[identifier];
            const updated = R.includes(value, selected)
              ? R.reject(R.equals(value), selected)
              : R.uniq([...selected, value]);
            onChange(updated);
          } else {
            onChange([...selected, newSelectedItem[identifier]]);
          }
          setSearchTerm("");
          scrollToEnd();
          break;
        case useCombobox.stateChangeTypes.InputChange:
          setSearchTerm(newInputValue);
          break;
        default:
          break;
      }
    }
  });

  const scrollToEnd = useCallback(() => {
    if (selectedItemsRef.current) {
      selectedItemsRef.current.scrollTop =
        selectedItemsRef.current.scrollHeight;
    }
  }, [selected]);

  return (
    <Container>
      <StyledSelection>
        <Label weight={labelWeight} {...getLabelProps()}>
          {label}
        </Label>

        {virtualized ? (
          <VirtualSelection
            showSelectAll={showSelectAll}
            selected={selected}
            data={data}
            keys={keys}
            showIcon={showIcon}
            placeholder={placeholder}
            isOpen={isOpen}
            selectedRef={selectedItemsRef}
            itemsPerRow={itemsPerRow}
            handleDeselectAll={handleDeselectAll}
            openMenu={openMenu}
            getSelectedItemProps={getSelectedItemProps}
            removeSelectedItem={removeSelectedItem}
            getInputProps={getInputProps}
            getDropdownProps={getDropdownProps}
            getComboboxProps={getComboboxProps}
          />
        ) : (
          <Selection
            showSelectAll={showSelectAll}
            selected={selected}
            data={data}
            keys={keys}
            showIcon={showIcon}
            placeholder={placeholder}
            isOpen={isOpen}
            selectedRef={selectedItemsRef}
            handleDeselectAll={handleDeselectAll}
            openMenu={openMenu}
            getSelectedItemProps={getSelectedItemProps}
            removeSelectedItem={removeSelectedItem}
            getInputProps={getInputProps}
            getDropdownProps={getDropdownProps}
            getComboboxProps={getComboboxProps}
          />
        )}
      </StyledSelection>
      <DropdownMenu {...getMenuProps()}>
        {isOpen && (
          <>
            {showSelectAll && filteredItems.length > 1 && (
              <SelectAllWrapper>
                <ToggleButton onClick={handleSelectAll}>
                  <ItemTitle>Select all</ItemTitle>
                </ToggleButton>
              </SelectAllWrapper>
            )}
            {filteredItems.length < 1 && (
              <SelectAllWrapper>
                <ItemTitle color={red500}>
                  {searchTerm.length > 0
                    ? `No results found for "${searchTerm}"`
                    : "No results found"}
                </ItemTitle>
              </SelectAllWrapper>
            )}
            {virtualized ? (
              <VirtualDropdown
                items={filteredItems}
                keys={keys}
                highlightedIndex={highlightedIndex}
                getItemProps={getItemProps}
                selected={selected}
                showIcon={showIcon}
                showCheckBox={showCheckBox}
                virtualRowHeight={virtualRowHeight}
              />
            ) : (
              <Dropdown
                items={filteredItems}
                keys={keys}
                highlightedIndex={highlightedIndex}
                getItemProps={getItemProps}
                selected={selected}
                showIcon={showIcon}
                showCheckBox={showCheckBox}
              />
            )}
          </>
        )}
      </DropdownMenu>
    </Container>
  );
};

export default MultiSelect;
